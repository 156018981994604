import React from 'react';
import './About.scss'
import { Link } from 'react-router-dom'

const About = () => {
    return (
        <div className="about-container white">
            <div className="about-top dark-grey">
                What is Axxion?
            </div>
            <article>
                <div>
                    Everyone on earth experiences life in a different way, and each person's perception should be recognized. Imagination is the cornerstone of our species' progression and requires cultivation. True innovation occurs when a group directs their ingenuity towards a shared vision. Axxion's purpose is to build this group and generate world-changing ideas through a tight sense of commitment and respect towards each other. 
                </div>
                <div>
                    The products Axxion offers are the surface of the brand: you can always expect high-quality, genuine, and unique items. Ten percent of all profits will be donated to support digital equality, providing impoverished areas with access to modern technology. Axxion's community is the deeper level, with many features on the way to encourage collaboration and communication. As members progress through the movement, increasing knowledge and insight will be gained from each other.
                </div>
            </article>
            <div className='about-bottom light-grey'>
                <div>Are you the kind of person Axxion is looking for?</div>
                <Link to='/movement'>Get updates about The Movement</Link>
            </div>
        </div>
    );
};

export default About;