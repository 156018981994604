import React from 'react'
import './Home.scss'
import Slider from "react-slick"
import { Link } from 'react-router-dom'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ProductPanel from './ProductPanel'
import { withContext } from '../../ContextAPI/ContextHOC';

function Home (props) {

    const settings = {
        arrows: true,
        //Look for arrows in CSS
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        autoplay: true,
        autoplaySpeed: 4000,
    }
    return (
        <div className="centered-container home-container white">
            <section className='home-main'>
                <Slider {...settings}>
                    {props.context.products.map(product => {
                        if(product.featured) {
                            return <ProductPanel key={product.id} {...product} />
                        }
                    })}
                </Slider>
            </section>
            <div className='home-bottom'>
                <div>The official Axxion movement is coming.</div>
                <Link to='/movement'>NOTIFY ME</Link>
            </div>
        </div>
    );
}

export default withContext(Home)