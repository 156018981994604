import React, { Component } from 'react';
import { withContext } from '../../ContextAPI/ContextHOC'
import SVGIcon from '../Miscellaneous/SVGIcon'
import { Redirect, Link } from 'react-router-dom'
import './Product.scss'

class Product extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedImage: this.props.context.products.find(product => product.id == this.props.match.params.id) ? this.props.context.products.find(product => product.id == this.props.match.params.id).images[0] : ''
        }
    }

    chooseImage = (src) => {
        this.setState({selectedImage: src})
    }

    render() {
        console.log('------------ this.props', this.props)
        const product = this.props.context.products.find(product => product.id == this.props.match.params.id)
        console.log('------------ product', product)
        if (product) {
            return (
                <div className='centered-container product-container white'>
                    <div className='inner-container light-grey'>
                        <Link to='/store' className='inaction store-back'><SVGIcon fill="#E5E5E5" width={16} name='arrow-back' />All Products</Link>
                        <div className='product-images'>
                            <div className='image-column'>
                                {product.images.map((image, i) => (
                                    <div className='hover-container' onClick={() => this.chooseImage(image)}>
                                        <img src={image} alt={`${product.name}-${1}`} />
                                        <div class={ this.state.selectedImage === image ? 'selected overlay' : "overlay" }></div>
                                    </div>
                                ))}
                            </div>
                            <img src={this.state.selectedImage} className='main-image' alt='main' width='auto'/>
                        </div>
                        <div className='product-description'>
                            <div>{product.name}</div>
                            <div className='product-flavor white'>
                                {product.flavor.map(text => <div>{text}</div>)}
                            </div>
                            <ul>
                                {product.details.map(detail => <li>{detail}</li>)}
                            </ul>
                            <div className="pricing">
                                <div>${product.price}</div>
                                { product.localStock && <a className='action'>PURCHASE</a> }
                                { product.amazonStock && <a href={product.link} className='action'>PURCHASE FROM AMAZON<SVGIcon fill="#E5E5E5" width={16} name='link' /></a> }
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <Redirect to='/' />
            )
        }
    }
}

export default withContext(Product)