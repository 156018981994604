import React, { Component } from 'react';
import './Store.scss'
import { Link } from 'react-router-dom'
import { withContext } from '../../ContextAPI/ContextHOC'

function Store (props) {
    const {products} = props.context
    const categories = []
    products.forEach(product => categories.includes(product.category) ? null : categories.push(product.category))
    console.log('------------ categories', categories)
    return (
        <div className='store-container white'>
            <div className="inner-container">
                <div>Shop Official Axxion Products</div>
                {categories.map((category, index) => (
                    <React.Fragment key={index}>
                        <div className="category-header dark-grey">
                            {category}
                        </div>
                        {products.map(product => {
                            if(product.category === category) {
                                return (
                                    <Link key={product.id} to={`/product/${product.id}`}>
                                        <div className="line-item">
                                            <div>
                                                <img src={product.mainImage} alt={product.title} />
                                                <div>{product.name}</div>
                                            </div>
                                            <div>${product.price}</div>
                                        </div>
                                    </Link>
                                )
                            }
                        })}
                    </React.Fragment>
                ))}
            </div>
            <div className='bottom-text'>More coming soon!</div>
        </div>
    );
}

export default withContext(Store)