import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import Profile from '../../Assets/Icons/profile.svg'
import SVGIcon from '../Miscellaneous/SVGIcon'
import { withContext } from '../../ContextAPI/ContextHOC'
import './Nav.scss'

class Nav extends React.Component {
    state = {
        toggleMenu: false
    }

    componentDidMount() {
        this.props.context.methods.getLocation()
    }

    componentDidUpdate(prevProps) {
        if(this.props.location !== prevProps.location) {
            this.props.context.methods.getLocation()
        }
    }

    render() {
        let location = this.props.context.location
        return (
            <div className="nav-container">
                <Link className='logo-container' to='/'>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            id="svg4573"
                            version="1.1"
                            viewBox="0 0 158.75 158.75"
                            height="80%"
                            width="17%">
                            <defs
                                id="defs4567" />
                            <g
                                transform="translate(0,-138.25)"
                                id="layer1">
                                <path
                                fill='#E5E5E5'
                                id="axxion-logo"
                                d="M 79.375,156.65644 7.9434574,280.37949 H 37.466868 L 79.375,256.1838 l 41.90813,24.19569 h 29.52341 z m 0,29.5963 18.640218,41.86663 -18.640218,9.91147 -18.640213,-9.91147 z m -24.768747,55.63115 8.400881,4.84988 -13.835328,7.35626 z m 49.537487,0 5.43445,12.20614 -13.835322,-7.35626 z"
                                />
                            </g>
                        </svg>
                        <span className='title'>AXXION</span>
                </Link>
                <nav>
                    <div className='icon-container'>
                        <Link to='/profile'><SVGIcon fill={ location === 'profile' ? 'rgb(121, 121, 121)' : '#E5E5E5'} width={50} name='profile' /></Link>

                        <Link to='/cart'><SVGIcon className="cart-icon" fill={location === 'cart' ? 'rgb(121, 121, 121)' : '#E5E5E5'} width={40} name='cart' /></Link>

                        <div onClick={() => this.setState(prevState => {return { toggleMenu: !prevState.toggleMenu }})}><SVGIcon fill='#E5E5E5' width={50} name='menu' /></div>
                    </div>
                </nav>
                    <div className={`drawer ${this.state.toggleMenu ? 'open' : ''}`}>
                        <Link to='/about' className={`nav-link ${location && location.includes('about') ? 'selected-nav' : ''}`}>ABOUT</Link>
                        <Link to='/store' className={`nav-link ${location && location.includes('store') ? 'selected-nav' : ''}`}>STORE</Link>
                        <Link to='/contact' className={`nav-link ${location && location.includes('contact') ? 'selected-nav' : ''}`}>CONTACT</Link>
                        <div onClick={() => this.setState(prevState => {return { toggleMenu: !prevState.toggleMenu }})}><SVGIcon fill='#E5E5E5' width={50} name='close' /></div>
                    </div>
            </div>
        )
    }
}

export default withRouter(withContext(Nav))