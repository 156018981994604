import React, { Component } from 'react';
import axios from 'axios'
import './NotifyMe.scss'
import SVGIcon from '../Miscellaneous/SVGIcon'
import LoadingDots from '../../Assets/LoadingDots/LoadingDots.jsx'

export default class SendEmail extends Component {
    state = {
        emailStatus: true,
        emailBody: '',
        email: '',
        loading: false,
        sent: false
    }

    handleChange = (type, val) => {
        this.setState({
            [type]: val,
            emailStatus: true
        })
    }
    
    validateEmail = () => {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        // console.log('------------ yes', re.test(String(this.state.email).toLowerCase()))
        if (re.test(String(this.state.email).toLowerCase())) {
            // this.setState({emailStatus: 'success'})
            this.setState({ loading: true })
            axios.post('/api/contact/email', { email: this.state.email, body: this.state.emailBody }).then(response => {
                this.setState({emailStatus: 'success', loading: false, sent: true})
            }).catch(error => {
                this.setState({loading: false})
                alert('Error occurred sending email. Please notify of this error through the Contact page.')
            })
        } else {
            this.setState({emailStatus: false})
        }
    }

    keyPressed = (event) => {
        if (event.key === "Enter") {
            this.validateEmail()
        }
    }

    render() {
        const { emailStatus, loading, sent } = this.state
        const { body } = this.props
        return (
            <div className='email-input'>
                { body &&
                    <textarea type="text" className="email-body" placeholder='Body' onChange={(e) => this.handleChange('emailBody', e.target.value)} />
                }
                <input className={emailStatus === 'success' ? 'green-border' : !emailStatus ? 'red-border' : ''} onChange={(e) => this.handleChange('email', e.target.value)} onKeyPress={this.keyPressed} placeholder='Your Email' value={this.state.email} />
                <div className="notification-text">
                    {emailStatus === 'success' ? 'Success!' : !emailStatus ? 'Please enter a valid email.' : ''}
                </div>
                { loading ? <LoadingDots /> : sent ? null : <button className={!emailStatus || emailStatus === 'success' ? 'action disabled' : 'action'} disabled={!emailStatus ? true : false} onClick={this.validateEmail}>{body ? <><SVGIcon fill='#E5E5E5' width={25} name="email"/>Send</> : "Keep Me Updated!"}</button> }
            </div>
        );
    }
}