import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import Home from './Components/Home/Home'
import About from './Components/About/About.jsx'
import Product from './Components/Store/Product.jsx'
import Store from './Components/Store/Store.jsx'
import NotifyMe from './Components/Contact/NotifyMe.jsx'
import ComingSoon from './Components/Miscellaneous/ComingSoon.jsx'
import Contact from './Components/Contact/Contact.jsx'

export default (
    <Switch>
        <Route exact path='/' component={Home} />
        <Route exact path='/movement' component={NotifyMe} />
        <Route exact path='/profile' component={ComingSoon} />
        <Route exact path='/cart' component={ComingSoon} />
        <Route exact path='/about' component={About} />
        <Route exact path='/contact' component={Contact} />
        <Route exact path='/store' component={Store} />
        <Route path='/product/:id' component={Product} />
        <Route path='/' render={() => {
                return <Redirect to='/' />
            }} />
    </Switch>
)