import React from 'react'
import { withContext } from '../../ContextAPI/ContextHOC'
import SVGIcon from './SVGIcon.jsx'
import './ComingSoon.scss'

function ComingSoon (props) {
    console.log('------------ props.history', props.history)
    return (
        <div className="outer-container">
            <div className="svg-container">
                <svg
                    className='circle'
                    xmlns="http://www.w3.org/2000/svg"
                    id="svg8"
                    version="1.1"
                    viewBox="0 0 158.74999 158.75"
                    height="600"
                    width="600">
                        <defs
                            id="defs2" />
                        <g
                            transform="translate(0,-138.24998)"
                            id="layer1">
                            <path
                            id="path861"
                            transform="matrix(0.26458332,0,0,0.26458332,0,138.24998)"
                            d="M 375.47461 68.714844 L 375.47461 170.47461 L 224.52539 170.47461 L 224.52539 68.865234 A 243.44674 243.44674 0 0 0 68.054688 227.38086 L 165.83203 227.38086 L 165.83203 378.33203 L 69.757812 378.33203 A 243.44674 243.44674 0 0 0 224.52539 531.11914 L 224.52539 410.23828 L 375.47461 410.23828 L 375.47461 531.28516 A 243.44674 243.44674 0 0 0 531.09375 375.47461 L 428.0957 375.47461 L 428.0957 224.52539 L 531.23047 224.52539 A 243.44674 243.44674 0 0 0 375.47461 68.714844 z "
                            style={{fill: '#E5E5E5', fillOpacity:1, stroke: '#000000', strokeWidth: 15, strokeMiterlimit: 4,strokeDasharray: 'none', strokeOpacity: 1}} />
                            <circle
                            r="64.411949"
                            cy="217.62497"
                            cx="79.374992"
                            id="path861-1"
                            style={{fill: '#E5E5E5', fillOpacity:1, stroke: '#000000', strokeWidth: 0, strokeMiterlimit: 4,strokeDasharray: 'none', strokeOpacity: 1}} />
                        </g>
                    </svg>
                <div className='small-slash'></div>
                <div id='centered'>  
                    <code>&#60;coming_soon&#8725;&#62;</code>
                    <button className='back-button action' onClick={() => {props.history.goBack()} }><SVGIcon fill="#E5E5E5" width={16} name='arrow-back' /> Back</button>
                </div>
            </div>
        </div>
    )
}

export default withContext(ComingSoon)