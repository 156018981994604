import React from 'react';
import './NotifyMe.scss'
import SendEmail from './SendEmail'

function NotifyMe () {
    return (
        <div className='notify-container light-grey'>
            <section>
                <div className='top-text'>Axxion exists to gather a community of people who produce <span>creative, unique, and insightful</span> ideas about the state of human existence and broader reality.</div>
                <div>Thanks for being here early! The Axxion Movement is still being built, and will improve over time. Enter your email below for occasional updates.</div>
            </section>
            <SendEmail />
        </div>
    );
};

export default NotifyMe;