import React, { Component } from 'react';
import SendEmail from './SendEmail'
import './NotifyMe.scss'

export default class Contact extends Component {
    render() {
        return (
            <div className='notify-container light-grey'>
                <section>
                    <div className='top-text'>Axxion strives for the absolute best <span>customer and community support</span>. <br/><br/>Feel free to use the form below to send suggestions, comments, or questions.</div>
                </section>
                <SendEmail body={true} />
            </div>
        );
    }
}