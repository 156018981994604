import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { withContext } from '../../ContextAPI/ContextHOC'
import SVGIcon from '../Miscellaneous/SVGIcon'

function ProductPanel (props) {
    const { name, mainImage, title, subtitle , id } = props
    return (
        <div className='product-panel'>
            <img src={mainImage} alt={name} />
            <div className='panel-right'>
                <div>
                    <div className='product-title'>{title}</div>
                    <div className='product-subtitle'>{subtitle}</div>
                </div>
                <Link to={`product/${id}`} className='action'><SVGIcon fill='#E5E5E5' width={30} name='cart' />Buy Now</Link>
            </div>
        </div>
    );
}

export default withContext(ProductPanel)