import React from 'react';
import SVGIcon from '../Miscellaneous/SVGIcon'
import './Footer.scss'
import { withContext } from '../../ContextAPI/ContextHOC'
import { Link } from 'react-router-dom'

const Footer = (props) => {
    console.log(props)
    let d = new Date();
    const year = d.getFullYear();
    return (
        <footer>
            <div className='social-container'>
                <a href='https://www.instagram.com/axxion_org'><SVGIcon fill='#E5E5E5' width={25} name='instagram' /></a>
                <a href='https://www.facebook.com/axxion.org'><SVGIcon fill='#E5E5E5' width={25} name='facebook' /></a>
                <a href='https://www.youtube.com/channel/UCnC7Fhdz8ZVN6IGi161qOfA'><SVGIcon fill='#E5E5E5' width={25} name='youtube' /></a>
                <a href='https://vm.tiktok.com/HDv7Wb/'><SVGIcon fill='#E5E5E5' width={25} name='tiktok' /></a>
            </div>
            <div className='footer-text'>Axxion by <a href='https://michaelkerr.tech'>Michael Kerr</a> ©{year}</div>
        </footer>
    );
};

export default withContext(Footer);