import React from 'react'
import axios from 'axios';

export const AppContext = React.createContext()

export default class ContextProvider extends React.Component {
    state = {
        user: null,
        location: '',
        products: [
            {
                id: 3,
                name: 'Axxion Groovegrip Switch Lite Case',
                mainImage: 'https://michaelkerr-projectmedia.s3.amazonaws.com/Axxion-Website-Images/NSLite/Cover-Plain(NSLite).png',
                images: ['https://michaelkerr-projectmedia.s3.amazonaws.com/Axxion-Website-Images/NSLite/Cartridge.jpg', 'https://michaelkerr-projectmedia.s3.amazonaws.com/Axxion-Website-Images/NSLite/Grey.jpg', 'https://michaelkerr-projectmedia.s3.amazonaws.com/Axxion-Website-Images/NSLite/Case.jpg', 'https://michaelkerr-projectmedia.s3.amazonaws.com/Axxion-Website-Images/NSLite/Cartrige2.jpg', 'https://michaelkerr-projectmedia.s3.amazonaws.com/Axxion-Website-Images/NSLite/Blue.jpg', 'https://michaelkerr-projectmedia.s3.amazonaws.com/Axxion-Website-Images/NSLite/Yellow.jpg'],
                flavor: ["What is life without a little fun? The Nintendo Switch Lite is the perfect portable system for playing your favorite games. Pick up the official Axxion case for comfortable protection!"],
                details: ["Flexible, durable material protects your Nintendo Switch Lite from drops and scratches", "Molded grips on the rear of the case provide a much more comfortable hold on the console", "Conveniently store two game cartridges inside of grips", "Translucent Frost Edition shows off your Nintendo Switch Lite’s original color", "Precise cutouts allow full console cooling and speaker capabilities"],
                title: 'Get a grip on the Nintendo Switch Lite',
                subtitle: 'Take your game to the next level',
                localStock: false,
                amazonStock: true,
                link: 'https://www.amazon.com/Groovegrip-Nintendo-Switch-Lite-Protection-Cartridge/dp/B07YCXB5PB',
                category: 'Cases',
                price: 10.99,
                featured: true
            },
            {
                id: 5,
                name: 'Ascendancy Premium T-Shirt',
                mainImage: 'https://michaelkerr-projectmedia.s3.amazonaws.com/Axxion-Website-Images/Ascendancy/Ascendancy+Grey.png',
                images: ['https://michaelkerr-projectmedia.s3.amazonaws.com/Axxion-Website-Images/Ascendancy/Ascendancy+Grey.jpg', 'https://michaelkerr-projectmedia.s3.amazonaws.com/Axxion-Website-Images/Ascendancy/Ascendancy+Black.jpg', 'https://michaelkerr-projectmedia.s3.amazonaws.com/Axxion-Website-Images/Ascendancy/Ascendancy+White.jpg', 'https://michaelkerr-projectmedia.s3.amazonaws.com/Axxion-Website-Images/Ascendancy/Ascendancy+Navy.jpg', 'https://michaelkerr-projectmedia.s3.amazonaws.com/Axxion-Website-Images/Ascendancy/Ascendancy+Blue.jpg', 'https://michaelkerr-projectmedia.s3.amazonaws.com/Axxion-Website-Images/Ascendancy/Ascendancy+Yellow.jpg'],
                flavor: ["Do you ever look into the sky and wonder what's behind it all?", "What unseen forces are out there, influencing our day-to-day lives?"],
                details: ["Men's and women's sizes available", "Lightweight, Classic fit, Double-needle sleeve and bottom hem", "Choose from multiple color options"],
                title: 'Ascendancy Design T-Shirt',
                subtitle: 'Control your universe',
                localStock: false,
                amazonStock: true,
                link: 'https://www.amazon.com/Ascendancy-Axxion-Planets-Premium-T-Shirt/dp/B081WTPYLN',
                category: 'Apparel',
                price: 20.99,
                featured: true
            },
            {
                id: 1,
                name: 'Axxion Hypercool Case (Zebra)',
                mainImage: 'https://michaelkerr-projectmedia.s3.amazonaws.com/Axxion-Website-Images/Zebra/Cover-Plain(Zebra).png',
                images: ['https://michaelkerr-projectmedia.s3.amazonaws.com/Axxion-Website-Images/Zebra/Zebra_Amazon_Picture_1.jpg', 'https://michaelkerr-projectmedia.s3.amazonaws.com/Axxion-Website-Images/Zebra/Zebra_Amazon_Picture_2.jpg', 'https://michaelkerr-projectmedia.s3.amazonaws.com/Axxion-Website-Images/Zebra/Zebra_Amazon_Picture_3.jpg', 'https://michaelkerr-projectmedia.s3.amazonaws.com/Axxion-Website-Images/Zebra/Zebra_Amazon_Picture_4.jpg', 'https://michaelkerr-projectmedia.s3.amazonaws.com/Axxion-Website-Images/Zebra/Zebra_Amazon_Picture_5.jpg', 'https://michaelkerr-projectmedia.s3.amazonaws.com/Axxion-Website-Images/Zebra/Zebra_Amazon_Picture_6.jpg'],
                flavor: ["Technological imagination is an extremely valuable trait.", "Innovate and invent whatever you dream.", "The future is forged by people like you."],
                details: ["Acrylic case for Raspberry Pi 4 model B (Pi not included) protects and enhances your single board computer", 'Three aluminum heatsinks and a high speed fan ensure maximum cooling across the whole surface of the Raspberry Pi', "Crystal clear top and bottom windows show off the ACT / PWR LED's and beautiful circuitry of the board", "Strategically cut layers allow unobstructed access to GPIO pins, camera / display slots, ports, and jacks", "Step-by-step instructions are included for quick and easy installation", "Zebra Edition"],
                title: 'Protect your technical creativity',
                subtitle: 'Don\'t leave your Raspberry Pi 4 undressed',
                localStock: false,
                amazonStock: true,
                link: 'https://www.amazon.com/Axxion-Hypercool-Raspberry-Heatsinks-Premium/dp/B07XD6PZ9W/',
                category: 'Cases',
                price: 13.87,
                featured: true
            },
            {
                id: 2,
                name: 'Axxion Hypercool Case (Tuxedo)',
                mainImage: 'https://michaelkerr-projectmedia.s3.amazonaws.com/Axxion-Website-Images/Tuxedo/Cover-Plain.png',
                title: 'Classy. Powerful. Beautiful.',
                images: ['https://michaelkerr-projectmedia.s3.amazonaws.com/Axxion-Website-Images/Tuxedo/Amazon_Picture_1.jpg', 'https://michaelkerr-projectmedia.s3.amazonaws.com/Axxion-Website-Images/Tuxedo/Amazon_Picture_2_Alt.jpg', 'https://michaelkerr-projectmedia.s3.amazonaws.com/Axxion-Website-Images/Tuxedo/Amazon_Picture_4.jpg', 'https://michaelkerr-projectmedia.s3.amazonaws.com/Axxion-Website-Images/Tuxedo/Amazon_Picture_5.jpg', 'https://michaelkerr-projectmedia.s3.amazonaws.com/Axxion-Website-Images/Tuxedo/Amazon_Picture_6.jpg', 'https://michaelkerr-projectmedia.s3.amazonaws.com/Axxion-Website-Images/Tuxedo/Amazon_Picture_8.jpg'],
                flavor: ["The Raspberry Pi is one of the best platforms for learning and experimenting with deep technology skills. Get inspired and create something truly amazing - just make sure to protect the little computer with an official Axxion case!"],
                details: ["Acrylic case for Raspberry Pi 4 model B (Pi not included) protects and enhances your single board computer", 'Three aluminum heatsinks and a high speed fan ensure maximum cooling across the whole surface of the Raspberry Pi', "Crystal clear top and bottom windows show off the ACT / PWR LED's and beautiful circuitry of the board", "Strategically cut layers allow unobstructed access to GPIO pins, camera / display slots, ports, and jacks", "Step-by-step instructions are included for quick and easy installation", "Tuxedo Edition"],
                subtitle: 'Tuxedo Edition Raspberry Pi 4 Case',
                localStock: false,
                amazonStock: true,
                link: 'https://www.amazon.com/Axxion-Hypercool-Raspberry-Heatsinks-Premium/dp/B07VF2LNBB/',
                category: 'Cases',
                price: 14.77,
                featured: false
            },
            {
                id: 4,
                name: 'DeclarX Design T-Shirt',
                mainImage: 'https://michaelkerr-projectmedia.s3.amazonaws.com/Axxion-Website-Images/DeclarX/Axxion+T-Shirt+(White).png',
                images: ['https://m.media-amazon.com/images/I/A1ntnF3PJOL._AC_CLa%7C2140%2C2000%7C612Th5t9fOL.png%7C0%2C0%2C2140%2C2000%2B0.0%2C0.0%2C2140.0%2C2000.0._SX679._SX._UX._SY._UY_.png', 'https://m.media-amazon.com/images/I/A1vAh9jhIlL._AC_CLa%7C2140%2C2000%7C612Th5t9fOL.png%7C0%2C0%2C2140%2C2000%2B0.0%2C0.0%2C2140.0%2C2000.0._SX679._SX._UX._SY._UY_.png', "https://m.media-amazon.com/images/I/C1ce8y0uOwS._AC_CLa%7C2140%2C2000%7C612Th5t9fOL.png%7C0%2C0%2C2140%2C2000%2B0.0%2C0.0%2C2140.0%2C2000.0._SX679._SX._UX._SY._UY_.png", 'https://m.media-amazon.com/images/I/B1qmQK-r4OS._AC_CLa%7C2140%2C2000%7C612Th5t9fOL.png%7C0%2C0%2C2140%2C2000%2B0.0%2C0.0%2C2140.0%2C2000.0._SX679._SX._UX._SY._UY_.png', 'https://m.media-amazon.com/images/I/B1OGJ8t%2B8ZS._AC_CLa%7C2140%2C2000%7C612Th5t9fOL.png%7C0%2C0%2C2140%2C2000%2B0.0%2C0.0%2C2140.0%2C2000.0._UX679_.png'],
                flavor: ["You're not one to let the world choose your path.", "You're not one to settle for the ordinary.", "You're not one to conform to the status quo."],
                details: ["Men's and women's sizes available", "Lightweight, Classic fit, Double-needle sleeve and bottom hem", "Choose from multiple color options"],
                title: 'Make a statement and stick to it',
                subtitle: 'Never let the world choose your path',
                localStock: false,
                amazonStock: true,
                link: 'https://www.amazon.com/Abstract-Design-Statement-Assertive-T-Shirt/dp/B07ZPR5M2S',
                category: 'Apparel',
                price: 16.77,
                featured: true
            },
        ],
        methods: {
            getLocation: () => {
                const path = window.location.pathname.split('')
                path.shift()
                const pathname = path.join("")

                if(!pathname) {
                    this.setState({
                        location: 'home'
                    })
                } else {
                    this.setState({
                        location: pathname
                    })
                }
            }
        }
    }

    componentDidMount() {

    }

    render() {
        return (
            <AppContext.Provider value={this.state}>
                {this.props.children}
            </AppContext.Provider>
        )
    }
}